

module controllers {
    export module master {

        interface IPackageQuotaControllerScope extends ng.IScope {
        }

        interface IPackageQuotaParams extends ng.ui.IStateParamsService {
        }

        export class packageQuotaCtrl {

            static $inject = ["$scope",
            "$rootScope",
            "generalService",
            "$q",
            "bsLoadingOverlayService",
            "entityService",
            "$timeout",
            '$uibModal',
            'permitService',
            '$state',
            '$stateParams',
            '$transitions',
            'statusService',
            'menuService',
            'cartonService'
            ];


            searchAccordian: boolean = false;

            packageSearch: interfaces.master.IPackagesSearch;
            selectedRestriction: interfaces.applicationcore.IDropdownModel;
            listRestriction: Array<interfaces.applicationcore.IDropdownModel> = [];
            selectedValid: number = -1;

            IsLoading: boolean = false;

            gridApi: uiGrid.IGridApi;         
            selectedRows: Array<interfaces.master.IPackagesLinkedToQuota> = [];
            currentPage: number = 1;
            pageSize: number = 15;

            myHook: any;

            sortName: string;
            sortDirection: string;

            filterNames: string[] = ["EntityCode","Carton","CountryCode","PackageType","DispatchDate","Valid","ValidDate","PreAllocated","SKUCode","ProductCode","Description","Category","TariffCode","Restriction","Permit","SKUQty","UoM"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];

            constructor(private $scope: IPackageQuotaControllerScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private permitService: interfaces.master.IPermitService,
                private $state: ng.ui.IStateService,
                private $stateParams: IPackageQuotaParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private menuService: interfaces.applicationcore.IMenuService,
                private cartonService: interfaces.master.ICartonService,
            ) {

                
                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.PackageQuota',
                    from: 'auth.MasterData.PackageQuota.**'
                }, () => {
                    this.loadPermitQuota();
                });

                  this.packageSearch = {
                  };
                  this.loadPermitQuota();
            }
            

            loadPermitQuota(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permitQuota'
                },
                    () => {
                        return this.permitService.getPackagesLinkedToPermitQuota().query(this.packageSearch, (result: Array<interfaces.master.IPackagesLinkedToQuota>) => {   

                            result.forEach((element, index) => {
                        
                                result[index].PreAllocated = element.Permit.Display?true:false;
                              });
                            
                            this.gvwPackageQuota.data = result;
                            this.gvwPackageQuota.totalItems = result.length;
                            this.selectedRows = [];

                            this.$timeout(() => {
                                if (this.gridApi) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.gridApi.grid.getColumn(name).filters[0].term || this.gridApi.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.gridApi.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.gridApi.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            search_click(){
                
                if (this.selectedRestriction){
                    this.packageSearch.restrictionId = this.selectedRestriction.Id;                    
                }

                if (this.selectedValid === -1){
                    this.packageSearch.valid = null;
                }
                this.loadPermitQuota();
                
            }

            showAll_click(){

                this.packageSearch = {                    
                };

                this.selectedRestriction = null;
                this.selectedValid = -1;

                this.loadPermitQuota();

                this.searchAccordian = false;
            }

            loadRestrictions() {
                return this.permitService.getRestrictionsForCurrentEntity().query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            ListPageChange(newPage, pageSize) {
                
            }

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;        
                
                this.gridApi.selection.on.rowSelectionChangedBatch(this.$scope,(selectedBatch:uiGrid.IGridRow[])=>{

                    for (let row of selectedBatch){
                        if (row.isSelected){
                            this.selectRow(row);
                        }
                        else{
                            this.unselectRow(row);
                        }
                    }
                });

                this.gridApi.selection.on.rowSelectionChanged(this.$scope, (row: uiGrid.IGridRow) => {
                    if (row.isSelected){
                        this.selectRow(row);
                    }
                    else{
                        this.unselectRow(row);
                    }
                });

            }

            selectRow(row: uiGrid.IGridRow){
                if (!this.selectedRows.find(x=> x.packageId === row.entity.packageId && x.PackageDetailSequenceNo === row.entity.PackageDetailSequenceNo)){
                    this.selectedRows.push(row.entity);
                }
            }

            unselectRow(row: uiGrid.IGridRow){
                this.selectedRows = this.selectedRows.filter(x=> {return !(x.packageId === row.entity.packageId && x.PackageDetailSequenceNo === row.entity.PackageDetailSequenceNo)});
            }

            public viewPackageDetail(packageId: number){
                this.getFilters();
                this.setFilters();
                this.$state.go("auth.MasterData.PackageQuota.PackageDetail", { packageId: packageId});
            }

            removeQuota(){
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Remove Quota from Selected Packages</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <div class="container">
                                    <div class="row vertical-align">
                                            Are you sure you want to remove quota from the {{totalCartons}} selected carton(s)?
                                    </div>
                                    <br/>

                                    <div   class="row vertical-align" ng-if="permitQuotaBalance.QuotaTypeClassificationValueId === 740 || permitQuotaBalance.QuotaTypeClassificationValueId === 798"> 
                                        <div class="col-md-2">Total Quota Value to be Removed</div>
                                        <div class="col-md-1">{{sumPreAllocatedValueRequested}}</div>
                                        <div class="col-md-1">{{permitQuotaBalance.CurrencyCode}}</div>
                                    </div>
                                    <div   class="row vertical-align" ng-if="permitQuotaBalance.QuotaTypeClassificationValueId === 739 || permitQuotaBalance.QuotaTypeClassificationValueId === 798"> 
                                            <div class="col-md-2">Total Quota Amount to be Removed</div>
                                            <div class="col-md-1">{{sumPreAllocatedAmountRequested}}</div>
                                            <div class="col-md-1">{{permitQuotaBalance.QuotaUoM}}</div>
                                    </div>                                                                          
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button"   ng-disabled="processing" ng-click="ok()">Remove Quota</button>
                                <button class="btn btn-secondary" type="button" ng-disabled="processing" ng-click="cancel()">Cancel</button>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, permitService: interfaces.master.IPermitService ) => {
                        $scope.selectedRows = this.selectedRows;
                        $scope.processing = false;

                        $scope.ok = () => {
                           // $uibModalInstance.dismiss();
                           this.bsLoadingOverlayService.wrap({
                            referenceId: 'permitQuota'
                                },
                            () => {
                                $scope.processing = true;
                                return this.permitService.reversePackagesSKUQuota().query(this.selectedRows, (result: interfaces.applicationcore.IMessageHandler) => {

                                    this.generalService.displayMessageHandler(result);
                                    $uibModalInstance.dismiss();
                                    if (!result.HasErrorMessage){
                                        this.loadPermitQuota();
                                    }
                                    $scope.processing = false;
                            
                            //this.selectedRows = [];
                                    }).$promise;
                                });

                        }
                        $scope.cancel = () => {
                            $uibModalInstance.dismiss();
                        }    

                        $scope.canAllocate = false;

                        $scope.sumPreAllocatedAmountRequested = this.selectedRows.reduce((accumulator, object) => {
                            return accumulator + object.SKUQty;
                          }, 0);

                          $scope.sumPreAllocatedValueRequested = this.selectedRows.reduce((accumulator, object) => {
                            return accumulator + object.SellingPrice;
                          }, 0);                              

                          $scope.totalCartons = this.selectedRows.length;      

                    },
                    size: "md",
                    resolve: {
                    }
                });
            }

            allocateQuota_Click(){
                const unique = this.selectedRows.map(item => item.RestrictionId)
                .filter((value, index, self) => self.indexOf(value) === index);

                if (unique.length>1){
                    this.generalService.displayMessage("Only one Restriction can be allocated to at a time.", Enum.EnumMessageType.Error);
                }       
                else{
                    this.allocateQuota();
                }
            }

            allocateQuota(){
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Allocate Quota for Restriction {{selectedRows[0].Restriction}}</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <div class="container">
                                    <div class="row vertical-align">
                                            Are you sure you want to Pre-Allocate quota to the {{totalCartons}} selected carton(s)?
                                    </div>
                                    <br/>
                                    <div class="row vertical-align">
                                         <div class="col-md-2">Permit  </div>
                                         <div class="col-md-4">
                                                <gts-dropdown ui-select-wrap 
                                                              name="Permit"
                                                              append-to-body="true" 
                                                              ng-model="permit" 
                                                              form="inputForm" 
                                                              ng-change="permitSelected(model)" 
                                                              load-data="loadPermits(searchText)" 
                                                              pagination-search="true" 
                                                              refresh-delay="1000">
                                                </gts-dropdown>
                                         </div>
                                    </div>     
                                    <div ng-if="permitQuotaBalance">                               
                                        <div   class="row vertical-align" ng-if="permitQuotaBalance.QuotaTypeClassificationValueId === 740 || permitQuotaBalance.QuotaTypeClassificationValueId === 798"> 
                                                <div class="col-md-2">Total Quota Value Available</div>
                                                <div class="col-md-1">{{permitQuotaBalance.QuotaValueBalance  | number : 2}}</div>
                                                <div class="col-md-1">{{permitQuotaBalance.CurrencyCode}}</div>
                                        </div>
                                        <div   class="row vertical-align" ng-if="permitQuotaBalance.QuotaTypeClassificationValueId === 739 || permitQuotaBalance.QuotaTypeClassificationValueId === 798"> 
                                                <div class="col-md-2">Total Quota Amount Available</div>
                                                <div class="col-md-1">{{permitQuotaBalance.QuotaAmountBalance  | number : 2}}</div>
                                                <div class="col-md-1">{{permitQuotaBalance.QuotaUoM}}</div>
                                        </div>  
                                        <div   class="row vertical-align" ng-if="permitQuotaBalance.QuotaTypeClassificationValueId === 740 || permitQuotaBalance.QuotaTypeClassificationValueId === 798"> 
                                            <div class="col-md-2">Total Quota Value to be Pre-Allocated</div>
                                            <div class="col-md-1">{{sumPreAllocatedValueRequested  | number : 2}}</div>
                                            <div class="col-md-1">{{permitQuotaBalance.CurrencyCode}}</div>
                                        </div>
                                        <div   class="row vertical-align" ng-if="permitQuotaBalance.QuotaTypeClassificationValueId === 739 || permitQuotaBalance.QuotaTypeClassificationValueId === 798"> 
                                                <div class="col-md-2">Total Quota Amount to be Pre-Allocated</div>
                                                <div class="col-md-1">{{sumPreAllocatedAmountRequested  | number : 2}}</div>
                                                <div class="col-md-1">{{permitQuotaBalance.QuotaUoM}}</div>
                                        </div>  
                                    </div>                                                                        
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button class="btn btn-primary"  ng-disabled="!canAllocate" type="button" ng-click="ok()">Allocate Quota</button>
                                <button class="btn btn-secondary" type="button" ng-click="cancel()">Cancel</button>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, permitService: interfaces.master.IPermitService ) => {
                        $scope.selectedRows = this.selectedRows;

                        $scope.ok = () => {
                           // $uibModalInstance.dismiss();
                           this.bsLoadingOverlayService.wrap({
                            referenceId: 'permitQuota'
                        },
                            () => {
                                this.selectedRows.map(a=>a.permitId=$scope.permitId);
                                $scope.canAllocate = false;
                                return this.permitService.preAllocatePackagesToPermit().query(this.selectedRows, (result: interfaces.applicationcore.IMessageHandler) => {
                                    $scope.canAllocate = true;
                                    this.generalService.displayMessageHandler(result);
                                    $uibModalInstance.dismiss();
                                    if (!result.HasErrorMessage){
                                        this.loadPermitQuota();
                                    }
                                    
                                    //this.selectedRows = [];
                                }).$promise;
                            });
                        }
                        $scope.cancel = () => {
                            $uibModalInstance.dismiss();
                        }    

                        $scope.canAllocate = false;
                        $scope.totalCartons = this.selectedRows.length;
                        $scope.permitId = null;
                        $scope.permit = null;

                        $scope.permitSelected = (model) => {

                            if (model){
                                $scope.permitId = model.Id;
                               

                                this.permitService.getPermitQuotaBalance().get({permitId:$scope.permitId},(permitQuotaBalance:interfaces.master.PermitQuotaBalance)=>{

                                    $scope.permitQuotaBalance = permitQuotaBalance;
                                    $scope.totalCartons = this.selectedRows.length;
        
                                    $scope.sumPreAllocatedAmountRequested = this.selectedRows.reduce((accumulator, object) => {
                                        return accumulator + object.SKUQty;
                                    }, 0);
        
                                    $scope.sumPreAllocatedValueRequested = this.selectedRows.reduce((accumulator, object) => {
                                        return accumulator + object.SellingPrice;
                                    }, 0);                              
        
                                    
                                    if (permitQuotaBalance.QuotaTypeClassificationValueId===739 && permitQuotaBalance.QuotaAmountBalance >= $scope.sumPreAllocatedAmountRequested){
                                        $scope.canAllocate = true;
                                    }
        
                                    if (permitQuotaBalance.QuotaTypeClassificationValueId===740 && permitQuotaBalance.QuotaValueBalance >=$scope.sumPreAllocatedValueRequested){
                                        $scope.canAllocate = true;
                                    }     
                                    
                                    if (permitQuotaBalance.QuotaTypeClassificationValueId===798 && (permitQuotaBalance.QuotaValueBalance >= $scope.sumPreAllocatedValueRequested && permitQuotaBalance.QuotaAmountBalance >= $scope.sumPreAllocatedAmountRequested)){
                                        $scope.canAllocate = true;
                                    }              
                                    
                                
                                });
                            }
                            else{
                                $scope.permitId = null;
                                $scope.permit = null;
                                $scope.canAllocate = false;
                                $scope.permitQuotaBalance = null;
                            }
                        }

                        $scope.loadPermits = (searchText:string) => {

                                return this.cartonService.GetPermitsForDropdown().query({
                                    packageId: this.selectedRows[0].packageId,
                                    packageRestrictionId: this.selectedRows[0].RestrictionId,
                                    searchText: searchText
                                }, () => {
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;                                            
                        }
                        

                    },
                    size: "md",
                    resolve: {
                    }
                });
            }

            loadPermits(row: uiGrid.IGridRow, searchText: string) {
                return this.cartonService.GetPermitsForDropdown().query({
                    packageId: row.entity.packageId,
                    packageRestrictionId: row.entity.RestrictionId,
                    searchText: searchText
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            
            downloadToExcel(){
                this.permitService.getPackagesLinkedToPermitQuotaExcel(this.packageSearch );
            }

            permitCodeDropdownChange(row: uiGrid.IGridRow,model: interfaces.applicationcore.IDropdownModel) {
             
                if (model) {
                    row.entity.permitId = model.Id;

                    if (this.gridApi) {
                        this.gridApi.rowEdit.setRowsDirty([row.entity]);
                    }
                }
            }

            public gvwPackageQuota: uiGrid.IGridOptions = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,                 
                enableFullRowSelection: true,
                enableRowSelection: true,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [15, 20, 50, 100],
                paginationPageSize: 15,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [
                    {
                        name: "Detail",
                        displayName: " ",
                        field: "Detail",
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: `
                        <div class="GridButton">
                            <center>
                                <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.packageQuotaCtrl.viewPackageDetail(row.entity.packageId)">
                                    <span class="fa fa-eye"></span>
                                </button>
                            </center>
                        </div>`,
                        width: 40,
                    },
                    {
                        name: "EntityCode",
                        displayName: "Entity",
                        field: "EntityCode",
                        width: 60,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "Carton",
                        displayName: "Carton",
                        field: "Carton",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "CountryCode",
                        displayName: "Country",
                        field: "CountryCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "PackageType",
                        displayName: "Type",
                        field: "PackageType",
                        width: 60,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "DispatchDate",
                        displayName: "Dispatch Date",
                        field: "DispatchDate",
                        enableCellEditOnFocus: false,
                        enableCellEdit: false,
                        width: 110,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="DateFrom-{{MODEL_COL_FIELD.$$hashKey}}"></gts-date>
                            </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="DispatchDate"></gts-date>
                                </div>`,
                        filter: {
                            condition: function (searchTerm, cellValue) {
                                if (cellValue) {
                                    return cellValue.isSame(searchTerm);
                                } else {
                                    return false;
                                }
                            }
                        },
                    },
                    {
                        name: 'Valid',
                        field: 'Valid',
                        displayName: 'Valid',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                        width: 60,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="checkbox" ng-model="colFilter.term"></div>
                        </div>`
                       
                    },
                    {
                        name: "ValidDate",
                        displayName: "Valid Date",
                        field: "ValidDate",
                        enableCellEditOnFocus: false,
                        enableCellEdit: false,
                        width: 110,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="DateFrom-{{MODEL_COL_FIELD.$$hashKey}}"></gts-date>
                            </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="ValidDate"></gts-date>
                                </div>`,
                        filter: {
                            condition: function (searchTerm, cellValue) {
                                if (cellValue) {
                                    return cellValue.isSame(searchTerm);
                                } else {
                                    return false;
                                }
                            }
                        },
                    },                             
                    {
                        name: 'PreAllocated',
                        field: 'PreAllocated',
                        displayName: 'Pre-Allocated',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                        width: 90,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="checkbox" ng-model="colFilter.term"></div>
                        </div>`
                       
                    },          
                    {
                        name: "SKUCode",
                        displayName: "SKU",
                        field: "SKUCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "ProductCode",
                        displayName: "Product",
                        field: "ProductCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }     ,
                    {
                        name: "Description",
                        displayName: "Description",
                        field: "Description",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "Category",
                        displayName: "Category",
                        field: "Category",
                        width: 120,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }    
                    ,
                    {
                        name: "TariffCode",
                        displayName: "Tariff",
                        field: "TariffCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    }    ,
                    {
                        name: "Restriction",
                        displayName: "Restriction",
                        field: "Restriction",
                        width: 220,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    } ,
                    {
                        name: "Permit",
                        displayName: "Permit",
                        field: "Permit",
                        width: 160,
                        cellTemplate: `                       
                        <div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}"><a href="{{grid.appScope.packageQuotaCtrl.ENV.ROOT_URL}}/ApplicationCore/GTS/index.html#!/MasterData/Permit/UpdatePermit/{{MODEL_COL_FIELD.Id}}" target="_blank">{{MODEL_COL_FIELD.Display}}</a></div>`,
                        editableCellTemplate: `
                                <gts-dropdown ng-show="!scope.row.entity.PreAllocated" ui-select-wrap name="Permit-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" ng-change="grid.appScope.packageQuotaCtrl.permitCodeDropdownChange(row,model)" load-data="grid.appScope.packageQuotaCtrl.loadPermits(row, searchText)" pagination-search="true" refresh-delay="1000"></gts-dropdown>`,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },      
                    /*,
                    {
                        name: "PERMIT",
                        displayName: "Permit",
                        field: "Permit",
                        width: 220,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}"><a href="{{grid.appScope.packageQuotaCtrl.ENV.ROOT_URL}}/ApplicationCore/GTS/index.html#!/MasterData/Permit/UpdatePermit/{{row.entity.permitId}}" target="_blank">{{MODEL_COL_FIELD}}</a></div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },*/
                    {
                        name: "SKUQty",
                        displayName: "Qty",
                        field: "SKUQty",
                        width: 60,
                        enableCellEdit: false,
                        cellClass: 'text-right',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD  | number : 2}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },       
                    {
                        name: "UoM",
                        displayName: "UOM",
                        field: "UoM",
                        width: 60,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                ]
                }

            

        }

      
        angular.module("app").controller("packageQuotaCtrl", controllers.master.packageQuotaCtrl);
    }
}